.verified {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100vh;
}
.logo {
  display: none;
}
.main {
  width: 54%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 24px;
}
.text {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  max-width: 524px;
}
.text > svg {
  width: 75px;
  height: 75px;
  margin-bottom: 30px;
}
.text h2 {
  font-weight: 800;
  font-size: 24px;
  line-height: 25px;
  color: var(--deep-purple);
  text-transform: uppercase;
  margin-bottom: 16px;

}
.text a {
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0.005em;
  color: var(--flood-blue);
}
@media (max-width: 765px) {
  .main {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .logo {
    display: block;
    width: 123px;
    position: absolute;
    top: 40px;
    left: 24px;
  }
  .text > svg {
    width: 48px;
    height: 48px;
    margin-bottom: 20px;
  }
  .text h2 {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 1px;
  }
  .text a {
    font-size: 14px;
    line-height: 20px;
  }
}
